<template>
  <div class="wameed-dashboard-page-content settings-page">
    <page-header
        :title="$t('settings.title')"
    />

    <section class="wameed-dashboard-page-content_body">
      <div class="settings-page--grid" v-if="show">
        <settings-card
            @onClick="companyDetails"
            icon="s-company-icon"
            :title="$t('settings.company_data')"
            v-permission="[permissions.contacts_settings]"
        />

        <settings-card
            @onClick="general"
            icon="s-gear-icon"
            :title="$t('settings.general')"
            v-permission="[permissions.general_settings]"
        />

        <settings-card
            @onClick="servicesFee"
            icon="s-wallet-icon"
            :title="$t('settings.service_fees')"
            v-permission="[permissions.readServiceFees]"
        />

        <settings-card
            @onClick="servicesDetail"
            icon="s-home-icon"
            :title="$t('settings.home')"
            v-permission="[permissions.readServiceDetail]"
        />

        <settings-card
            @onClick="workDays"
            icon="s-date-icon"
            :title="$t('settings.work_days')"
            v-permission="[permissions.readWorkDays]"
        />

        <settings-card
            @onClick="sysUsers"
            icon="s-date-icon"
            :title="$t('settings.sys_users')"
            v-permission="[permissions.readAdminUsers]"
        />

        <settings-card
            @onClick="sendNotifications"
            icon="s-notifications-icon"
            :title="$t('settings.send_notification')"
            v-permission="[permissions.sendNotifications_settings]"
        />

        <settings-card
            @onClick="paymentGateway"
            icon="s-payment-icon"
            :title="$t('settings.payment_gateway')"
            v-permission="[permissions.readPaymentGetaways_settings]"
        />
        <settings-card
            @onClick="vehicles"
            icon="s-vehicles-icon"
            :title="$t('settings.vehicles')"
            v-permission="[permissions.readVehicleTypes]"
        />

        <settings-card
            @onClick="orderTypes"
            icon="s-order-types-icon"
            :title="$t('settings.order_types')"
            v-permission="[permissions.readOrderTypes]"
        />


        <settings-card
            @onClick="orderAppMessages"
            icon="s-app-messages-icon"
            :title="$t('settings.app_messages')"
            v-permission="[permissions.readAppMessages]"
        />

        <settings-card
            @onClick="classifications"
            icon="s-classifications-icon"
            :title="$t('settings.classifications')"
            v-permission="[permissions.readClassifications]"
        />

        <settings-card
            @onClick="logs"
            icon="s-order-types-icon"
            :title="$t('logs.title')"
            v-permission="[permissions.readLogs]"
        />


      </div>
    </section>
  </div>
</template>

<script>

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import SettingsCard from "@/views/pages/settings/components/settingsCard";
import {permissions as permissionsEnum} from "@/enums/permissions.enum";

export default {
  components: {
    SettingsCard,
    PageHeader
  },
  data() {
    return {
      permissions: permissionsEnum,
      show: false,
    };
  },

  methods: {
    companyDetails() {
      this.$router.push({
        name: 'settings-contact',
        params: {lang: this.$i18n.locale},
      });
    },


    paymentGateway() {
      this.$router.push({
        name: 'settings-payments',
      });
    },



    workDays() {
      this.$router.push({
        name: 'work_days',
      });
    },


    orderTypes() {
      this.$router.push({
        name: 'settings-order-types',
      });
    },

    vehicles() {
      this.$router.push({
        name: 'settings-vehicles',
      });
    },

    orderAppMessages() {
      this.$router.push({
        name: 'settings-app-messages',
      });
    },

    logs() {
      this.$router.push({
        name: 'settings-logs',
      });
    },

    classifications() {
      this.$router.push({
        name: 'settings-classifications',
      });
    },

    sysUsers() {
      this.$router.push({
        name: 'system-users',
      });
    },

    general() {
      this.$router.push({
        name: 'settings-general',
      });
    },


    servicesFee() {
      this.$router.push({
        name: 'settings-services-fee',
      });
    },

    servicesDetail() {
      this.$router.push({
        name: 'settings-services-detail',
      });
    },

    sendNotifications() {
      this.$router.push({
        name: 'system-notifications',
      });
    },
  },
  mounted() {
    this.$store.commit('loadingStart', null);
    setTimeout(() => {
      this.show = true;
      this.$store.commit('loadingFinish', null);
    }, 1000);

  }
};
</script>

